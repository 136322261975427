<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
          :module_name="page.name"
          :record="record"
          @change="save"
          @archive="archive"
          :loading="loading"
          :model="page.model">
          <template v-slot:main_fields>
            <v-row>
              <v-col>
                <template v-for="field in usual_fields">
                  <base-field
                      :key="field.name"
                      :value="record[field.name]"
                      :field="field"
                      @change="usualFieldDataChanged"/>
                </template>
              </v-col>
              <v-col>
                <base-log-field class="mt-5" :model_type="$models[page.name]" :model_id="record.id"/>
                <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
              </v-col>
            </v-row>
          </template>
          <template v-if="custom_fields.length > 0" v-slot:module_fields>
            <template v-for="field in custom_fields">
              <base-field
                  :key="field.name"
                  :value="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
            </template>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <base-planner-scheme :loading="loading" :record="record"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="schemesessions_table" :page="page" :parent_module_id="record.id" relation_name="schemesessions" @create="createSchemesession" @edit="editSchemesession" @change="load"/>
          <base-table-includable ref="assessors_table" :page="page" :parent_module_id="record.id" relation_name="assessors" @edit="editAssessor" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <create-schemesession ref="create_schemesession" modal_name="create_schemesession" @refresh="load" :parent="record"/>
    <edit-schemesession ref="edit_schemesession" modal_name="edit_schemesession" @refresh="load" :parent="record"/>
    <edit-note modal_name="edit_note" :module_name="page.name" :model_id="record.id" ref="edit_note" @document_preview="preview" @refresh="load" :model_type="$models[page.name]"/>
    <create-note modal_name="create_note" :module_name="page.name" :model_id="record.id" ref="create_note" @refresh="load"/>
    <document-edit modal_name="document_edit" ref="document_edit"/>
  </v-container>
</template>

<script>
import CreateSchemesession from "./schemesessions/Create";
import EditSchemesession from "./schemesessions/Edit";
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import EditNote from "../notes/Edit";
import CreateNote from "../notes/Create";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import DocumentEdit from "../documents/Edit";
import BasePlannerScheme from "../../components/commonComponents/BasePlannerScheme";

export default {
  components: {
    DocumentEdit,
    BaseTableIncludable,
    BaseTabPanel,
    CreateSchemesession,
    EditSchemesession,
    EditNote,
    CreateNote,
    BaseBreadcrumb,
    BasePlannerScheme
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("schemes"),
        name: "schemes",
        model: "scheme",
      },
      record: {
        custom_fields: {}
      },
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      templates: [],
      companies: [],
    };
  },
  created() {
    this.getCompanies();
    this.getTemplates();
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.loading = false;
          this.record = response.data;
          if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
            this.record.custom_fields = {};
          }
          if (!this.record.exam_tags) {
            this.record.exam_tags = [];
          }
          if(this.companies.length > 0 && this.templates.length > 0) {
            this.getFields();
          }
          this.updateIncludableTables();
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateIncludableTables() {
      this.$refs.schemesessions_table.records = this.$lodash.sortBy(this.record.scheme_sessions, "sort_order");
      this.$refs.schemesessions_table.updateHeaders('scheme_session', this.record);
      this.$refs.assessors_table.records = this.record.assessors;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.schemesessions_table.selected = [];
      this.$refs.assessors_table.selected = [];
      this.$refs.notes_table.selected = [];
    },
    save() {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if (!error) {
          this.loading = true;
          this.$http
              .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure?")).then((res) => {
          if (res) {
            this.$http
              .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
              .then(() => {
                this.$router.push("/" + this.page.name);
                this.loading = false;
                this.$toasted.success(this.$store.getters.translate("successfully_deleted"));
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
          }
        }
      );
    },
    editAssessor(item) {
      this.$router.push("/assessors/" + item.id);
    },
    editSchemesession(item) {
      this.$refs.edit_schemesession.openModal('edit_schemesession');
      this.$refs.edit_schemesession.load(item.id);
    },
    createSchemesession() {
      this.$refs.create_schemesession.openModal('create_schemesession');
    },
    async getTemplates() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/templates")
          .then((response) => {
            this.templates = [];
            response.data.data.forEach((option) => {
              this.templates.push({
                value: option.id,
                text: option.name,
              });
            });
            if(this.companies.length > 0 && this.record.id) {
              this.getFields();
            }
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    async getCompanies() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/companies")
        .then((response) => {
          this.companies = [];
          response.data.data.forEach((option) => {
            this.companies.push({
              value: option.id,
              text: option.name,
            });
          });
          if(this.templates.length > 0 && this.record.id) {
            this.getFields();
          }
        })
        .catch((error) => {
          this.$toasted.error(error);
        });
    },
    editNote(item) {
      this.$refs.edit_note.openModal('edit_note');
      this.$refs.edit_note.load(item.id);
    },
    createNote() {
      this.$refs.create_note.openModal('create_note');
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_update=true&model_type=" + this.page.model)
          .then((response) => {
            this.loading = false;
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.name === 'company_id') {
                  field.available_options = this.companies;
                }
                else if(field.name === 'template_id') {
                  field.available_options = this.templates;
                }
                else if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>
